.content-view.el-container {
  width: 1400px;
  min-height: 700px;
  margin: 0 auto;
  padding-top: 10px;
}

.el-container.is-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.el-my-style {
  background-image: url(../../images/game-bg.jpg);
}

.el-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0;
}

.el-container.style {
  min-height: 600px;
  background-color: #fff;
  border-radius: 10px;
}

aside.el-aside {
  max-width: 250px;
  margin: 20px 12px 0 20px;
  border-color: #43a7fc;
}

.el-aside .el-menu {
  background: #fff;
  border-color: #43a7fc !important;
  border-radius: 15px;
  border: 1px solid;
  overflow: hidden;
}

.el-menu:before {
  display: table;
  content: "";
}

.el-aside .el-menu-item {
  color: #4d4d4d;
  border-color: #43a7fc;
  border-bottom: 1px solid #43a7fc !important;
  line-height: 50px;
  height: 50px;
  text-align: left;
  text-indent: 40px;
  position: relative;
}

.el-aside .el-menu-item:hover,
.el-aside .el-menu-item.is-active {
  color: #fff;
  background: #2675dc;
  border-color: #0096ff;
}

.el-aside .el-menu-item {
  border-bottom: 1px solid;
  line-height: 50px;
  height: 50px;
  text-align: left;
  text-indent: 40px;
  position: relative;
}

.el-menu-item {
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
}

.el-menu-item img {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 28px;
  height: 24px;
  background-size: auto 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.el-menu-item * {
  vertical-align: middle;
}

.el-main.app-main {
  margin-left: 10px;
  border-left: 1px solid rgb(209, 209, 209);
}
