.act-box {
  background: url(../../images/bg-act.jpg) no-repeat top center #07082a;
  background-attachment: fixed;
  color: #fff;
}

.tab_main {
  position: relative;
  //   margin: 20px 0;
  //   padding: 28px 80px;
  //   min-height: 734px;
  background: url(../../images/box_bg.png) no-repeat;
  background-size: 100% 100%;

  // img {
  //   cursor: pointer;
  // }

  a {
    color: white;
    position: absolute;
    bottom: 10%;
    left: 10%;
    padding: 8px 20px;
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    border-radius: 30px;
  }
}

.khuyen-mai-bg {
  position: relative;
  background-image: url(../../images/ad_popbox.png);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 900px;
  background-size: 100% 100%;
  padding: 100px 30px 30px;
  max-width: 1080px;
  max-height: 750px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.khuyen-mai-bg .img-km {
  overflow-y: scroll;
  width: 80%;
  height: 600px;
  margin-left: 10%;
}

.khuyen-mai-bg .transperent {
  width: 60px;
  height: 60px;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
}
