.hot-game__bubble {
  position: fixed;
  left: 80px;
  bottom: 10dvh;
  z-index: 99999999999;

  img {
    cursor: pointer;
  }
}

.list-game__hot {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.3);
  height: 180px;
  position: fixed;
  left: 120px;
  bottom: 22dvh;
}

.list-game__hot.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}
