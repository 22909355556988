.container-modal {
  background-color: white;
  width: 30%;
  height: 400px;
  position: fixed;
  top: 20%;
  left: 35%;
  border-radius: 10px;
  color: black;
  font-size: 16px;

  .header-setup__chip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border-bottom: 1px solid rgb(172, 172, 172);
  }

  .body-setup__chip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 10px 30px 0px 30px;

    .list-chip__setup {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      .custom-chip__isValue {
        position: relative;
        background-image: url(../../../images/game_images/img-chip-customize.png);
        width: 50px;
        height: 51px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #9e4212;
        }

        .active {
          width: 56px;
          height: 58px;
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-image: url(../../../images/game_images/active_chip.png);
          position: absolute;
          top: -4px;
          left: -3px;
        }
      }

      .item {
        width: 22%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
          width: 50px;
          height: 51px;
        }

        .active {
          width: 56px;
          height: 58px;
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-image: url(../../../images/game_images/active_chip.png);
          position: absolute;
          top: 4px;
        }
      }
    }
  }

  .custom-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    img {
      width: 50px;
      height: 51px;
      margin-top: 10px;
    }

    .custom-chip__isValue {
      position: relative;
      background-image: url(../../../images/game_images/img-chip-customize.png);
      width: 50px;
      height: 51px;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #9e4212;
      }

      .active {
        width: 56px;
        height: 58px;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-image: url(../../../images/game_images/active_chip.png);
        position: absolute;
        top: -4px;
        left: -3px;
      }
    }

    .input-custom_chip {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 80px;

      input {
        border: 1px solid #999;
        border-radius: 5px;
      }

      button {
        padding: 0px 10px;
        height: 28px;
        border-radius: 5px;
        background: linear-gradient(180deg, #74caff, #1779ff);
        color: white;
      }
    }
  }

  .button-setting__chips {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;

    .button {
      padding: 5px 30px;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }

    .button-cancel {
      background: linear-gradient(180deg, #fee379, #fe8148);
    }

    .button-confirm {
      background: linear-gradient(180deg, #74caff, #1779ff);
    }
  }
}
