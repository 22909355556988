.ft-top {
  // background-color: $c-primary;
  background-image: url(../../images/top_footer_bg.jpg);
  font-size: 12px;
  color: #fff;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  .item-left {
    width: 50%;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 15%;
      right: -40px;
      border-left: 1px solid #ffffff30;
      width: auto;
      height: 70%;
    }

    .item {
      margin-top: 20px;
    }
  }

  .item-right {
    width: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;

    .footer_shortcut-box_item {
      padding: 8px;

      .footer_shortcut-box_service {
        display: inline-block;
        width: 330px;
        height: 60px;
        margin-bottom: 5px;
        color: #fff;
        font-size: 20px;
        text-align: left;
        position: relative;
        display: block;
        overflow: visible;

        &::after {
          position: absolute;
          width: 95px;
          height: 82px;
          bottom: 1px;
          right: 8px;
          content: "";
          background: url(../../images/7x24-gal.png) no-repeat center
            transparent;
        }

        .footer_shortcut-box_service-content2 {
          font-size: 12.5px;
          line-height: 20px;
          width: 204px;
          height: 60px;
          padding: 10px 20px;
          border: 1px solid #ffffff30;
          border-left: 0;
          position: relative;
          overflow: visible;
          float: right;

          .footer_shortcut-box_service-content2 {
            font-size: 12.5px;
            line-height: 20px;
            width: 204px;
            height: 60px;
            padding: 10px 20px;
            border: 1px solid #ffffff30;
            border-left: 0;
            position: relative;
            overflow: visible;
          }
        }

        .footer_shortcut-box_service-content {
          position: relative;
          z-index: 1;
          width: 126px;
          height: 100%;
          background-color: #0056fe;
          border: 1px solid #0056fe;
          padding: 15px 10px;
          float: left;

          .footer_shortcut-box_service-icon {
            display: inline-block;
            width: 34px;
            height: 30px;
            margin-right: 5px;
            background: url(../../images/7x24h-icon.png) no-repeat center
              transparent;
          }

          .footer_shortcut-box_service-text {
            display: inline;
            line-height: 34px;
            float: left;
          }
        }
      }
    }

    .item {
      margin-top: 20px;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      padding: 0 4% 0 2%;
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1.4rem;
          height: 6.3rem;
          background: url("../../images/foot-line.png") no-repeat center;
          background-size: 100% 100%;
        }
      }
      a {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        p {
          padding: 1.5rem;
        }
      }
    }
  }
}
.ft-center {
  height: 10rem;
  border-top: 1px solid #142f75;
  background-color: $c-secondary__color;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  img {
    display: inline-block;
    margin: 0 auto;
  }
  &:after {
    content: "";
    position: absolute;
    top: -2.3rem;
    left: 50%;
    width: 40rem;
    height: 3rem;
    border-radius: 30%;
    background: #fff;
    filter: blur(25px) brightness(1.5);
    transform: translateX(-50%);
  }
}
.ft-bottom {
  border-top: 1px solid #142f75;
  background-color: $c-primary;
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-transform: uppercase;
    height: 4.3rem;
    color: #fff;
  }
}
