#adjumpbox {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 19880808;
  background: rgba(0, 0, 0, 0.3);
}

.content-adjumpbox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 720px;
  max-width: 100%;
}

.content-main img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  display: block;
}

.close {
  color: rgb(255, 255, 255);
  background: rgb(51, 51, 51);
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close span {
  color: white;
}
