@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}
html[theme="dark_mode"] {
  filter: invert(100%) hue-rotate(180deg);
}
/* Workarounds and optical adjustments. */

/* Firefox workaround: Set the background colour for the html
     element separately because, unlike other browsers, Firefox
     doesn’t apply the filter to the root element’s background. */
html[theme="dark_mode"] {
  background-color: #111;
}

/* Do not invert media (revert the invert). */
html[theme="dark_mode"] img,
html[theme="dark_mode"] video,
html[theme="dark_mode"] iframe {
  filter: invert(100%) hue-rotate(180deg);
}

/* Improve contrast on icons. */
html[theme="dark_mode"] .icon {
  filter: none;
}

/* Re-enable code block backgrounds. */
html[theme="dark_mode"] pre {
  filter: invert(6%);
}

/* Improve contrast on list item markers. */
html[theme="dark_mode"] li::marker {
  color: #666;
}
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
}
b,
strong,
th {
  font-weight: bold;
}
i,
em {
  font-style: italic;
}
sub,
sup {
  font-size: 75%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 20px 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: none;
}
ol,
ul,
li {
  padding: 0;
  list-style: none;
  margin: 0;
}
label {
  font-weight: normal;
}
input[type="text"],
input[type="email"],
input[type="password"] {
  padding-left: 12px;
  padding-right: 12px;
}
select {
  padding-left: 8px;
  cursor: pointer;
}
input[type="number"] {
  padding-left: 5px;
}
textarea {
  padding: 8px 12px 5px 12px;
}
input,
textarea,
select,
button,
a,
button:hover,
button:active,
button:focus {
  outline: none;
}
button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
* {
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-top: constant(safe-area-inset-top);
}
img {
  max-width: 100%;
  vertical-align: middle;
  object-fit: cover;
}

@import "var";
// @include make-col(f20,20%);
.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-width__style {
  max-width: 1480px !important;
}

.row {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  > .col,
  > [class*="col-"] {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.pad-0 {
  @include pad(0px);
}
.pad-5 {
  @include pad(5px);
}
.pad-8 {
  @include pad(8px);
}
.pad-10 {
  @include pad(10px);
}

.margin-auto {
  @include mar(auto);
}

.col-mar-0 {
  @include col-mar(0px);
}
.col-mar-5 {
  @include col-mar(5px);
}
.col-mar-8 {
  @include col-mar(8px);
}
.col-mar-10 {
  @include col-mar(10px);
}
.col-mar-30 {
  @include col-mar(3rem);
}

.fx-mid {
  align-items: center;
}
.ifx-top {
  align-self: start;
}
.ifx-bot {
  align-self: end;
}
.ifx-mid {
  align-self: center;
}

.relative {
  position: relative;
}
.static {
  position: static;
}
.absolute {
  position: absolute;
}

.clearfix {
  &:before,
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.ic {
  @include middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.smooth {
  @include smooth(0.2s);
  @include bfhide();
}

@media all and (max-width: 991px) {
  .md-pad-7 {
    margin: 0 -7px;
    & > [class^="col-"] {
      padding: 0 7px;
    }
  }
  .md-pad-5 {
    margin: 0 -5px;
    & > [class^="col-"] {
      padding: 0 5px;
    }
  }
}
@media all and (max-width: 767px) {
  .sm-pad-7 {
    margin: 0 -7px;
    & > [class^="col-"] {
      padding: 0 7px;
    }
  }
  .sm-pad-5 {
    margin: 0 -5px;
    & > [class^="col-"] {
      padding: 0 5px;
    }
  }
  .tb-responsive {
    display: block;
    tbody,
    tfoot,
    tr,
    th,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    tr:nth-child(2n) {
      background: #f5f5f5;
    }
    td {
      position: relative;
      padding: 10px 10px 10px 50%;
      &:before {
        content: attr(data-title);
        position: absolute;
        left: 0;
        width: 50%;
        padding-right: 15px;
      }
    }
  }
}
@media all and (max-width: 575px) {
  .xs-pad-7 {
    margin: 0 -7px;
    & > [class^="col-"] {
      padding: 0 7px;
    }
  }
  .xs-pad-5 {
    margin: 0 -5px;
    & > [class^="col-"] {
      padding: 0 5px;
    }
  }
}

@include slick();
@include i-select();
@include s-content();
// @include table();
// @include s-social();
// @include c-img();

.hc-upload {
  position: relative;
  .hc-del {
    position: absolute;
    width: 24px;
    height: 24px;
    background: rgba(#000, 0.6);
    color: #fff;
    font-size: 21px;
    line-height: 1;
    text-align: center;
    border-radius: 50%;
    top: -12px;
    right: -12px;
    cursor: pointer;
    &:hover {
      background: rgba(#000, 0.85);
    }
  }
}
