.background_notification {
  background-image: url(../../images/8fc0b1adc87eed2490b37b9966e55b7c.jpg);
  height: 500px;
  width: 100%;

  .content_notification {
    padding: 0px 20px;

    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 10px px 50px;
      max-height: 500px;
      overflow: auto;

      li {
        color: #454545;
        font-size: 13px;
        line-height: 27px;
        list-style: disc;
        white-space: pre-line;
        border-bottom: 1px dashed #999;
      }
    }
  }
}
