.el-main {
    background: #fff;
    color: #666;
    border-top-right-radius: 12px;
}

.el-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
}

.el-tabs--card > .el-tabs__header {
    border-bottom: 1px solid #feba44;
}

.el-tabs--card > .el-tabs__header {
    border-bottom: 1px solid #feba44;
}

.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 15px;
}

.el-tabs__nav-scroll {
    overflow: hidden;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    transform: translateX(0px);
}

.el-tabs__nav {
    white-space: nowrap;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    float: left;
    z-index: 2;
}

.el-tabs__item.is-active,
.el-tabs__item.is-active,
.el-tabs__item.is-active {
    color: #fff;
    background: #feba44;
}

.el-tabs__item {
    padding: 0 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    position: relative;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #feba44;
}

.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: none;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid transparent;
    -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
