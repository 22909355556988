.cas-home {
  .slick-dots {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    li {
      width: 1rem;
      height: 1rem;
      background: rgba(#fff, 0.7);
      border: none;
      &.slick-active {
        background: #007aff;
      }
    }
  }
  img {
    width: 100%;
  }
}
.home-news-run {
  background-color: #000;
  border-top: 1px solid #4d5c88;
  border-bottom: 1px solid #4d5c88;
  color: #fff;
  .wrap-run {
    display: flex;
    align-items: center;
    height: 4.5rem;
    .title {
      margin-right: 1rem;
    }
  }
}
.home-hotGame {
  background: url("../../images/bg.jpg") no-repeat center;
  background-size: cover;
  padding: 4rem 0;
}
.head-img {
  text-align: center;
  margin-bottom: 4rem;
}
.hotLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 95%;
  margin: 0 auto;
  .item {
    position: relative;
    display: block;
    flex: 0 0 23%;
    perspective: 600;
    transform-style: preserve-3d;
    transition: 0.6s ease-in-out;
    img {
      width: 100%;
      filter: brightness(90%);
    }
    span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: 0.6s ease-in-out;
    }
    .front {
      transform: rotateY(0);
      position: relative;
    }
    .back {
      opacity: 0;
      visibility: hidden;
      transform: rotateY(180deg);
    }
    &:hover {
      .front {
        opacity: 0;
        visibility: hidden;
        transform: rotateY(-180deg);
      }
      .back {
        opacity: 1;
        visibility: visible;
        transform: rotateY(0);
      }
    }
  }
}
.home-about {
  background: url("../../images/bg2.jpg") no-repeat center;
  background-size: cover;
  padding: 4rem 0;
}
.wrap-about {
  display: flex;
  .img {
    flex-grow: 1;
    margin-top: -8rem;
    text-align: center;
  }
  .ct {
    flex: 0 0 36%;
    margin-left: 5%;
    .title {
      color: #fdf3a3;
      font-size: 1.8rem;
      line-height: 1;
      letter-spacing: 2px;
      margin-bottom: 0.6rem;
    }
    .desc {
      letter-spacing: 2px;
      line-height: calc(18 / 13);
      color: #fff;
    }
    .app {
      margin-top: 4rem;
      display: flex;
      .left {
        flex: 0 0 52%;
      }
      .qr-code {
        margin-left: 1.5rem;
        flex: 0 0 33.5%;
      }
    }
  }
}
/*Fix box left*/
.fix_box-left {
  position: fixed;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  left: 0.3rem;
}
.fix_aside-nav {
  position: relative;
  width: 28rem;
  height: 34rem;
}

.item-menu {
  z-index: 1;
  width: 13.4rem;
  height: 13.4rem;
  background-image: url("../../images/fl-m1_bg.png");
  background-size: contain !important;
  background-repeat: no-repeat;
  animation: rota 5s ease 1.4s infinite;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -9rem;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s;
}

.item-fix {
  display: inline-block;
  width: 10.8rem;
  height: 10.8rem;
  border-width: 2rem;
  border-style: dashed;
  border-color: rgba(255, 255, 0, 0);
  background-image: url("../../images/fl-m2_bg.png");
  background-position: 50% 50%;
  background-origin: border-box;
  background-repeat: no-repeat;
  transition: 1s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -90px;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s;
}

.item-fix:hover {
  border-width: 0;
  border-color: rgba(255, 255, 0, 0.7);
}

.fix_aside-nav .close-btn {
  position: absolute;
  top: 68%;
  left: 10%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  cursor: pointer;
  color: rgba(#fff, 0.5);
  border: 1px solid rgba(#fff, 0.5);
  padding-top: 1px;
  transition: 0.3s;
  &:hover {
    color: #fff;
    border-color: #fff;
  }
}

.fix_aside-nav:hover .item-fix-1 {
  transform: translate3d(0, -128%, 0);
}

.fix_aside-nav:hover .item-fix-2 {
  transform: translate3d(100%, -70%, 0);
}

.fix_aside-nav:hover .item-fix-3 {
  transform: translate3d(100%, 70%, 0);
}

.fix_aside-nav:hover .item-fix-4 {
  transform: translate3d(0, 128%, 0);
}

.fix_aside-nav .m-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-content: stretch;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  color: #fff;
}

.fix_aside-nav .m-center h3,
.fix_aside-nav .m-center img,
.fix_aside-nav .m-center p {
  display: inline-block;
  text-transform: uppercase;
}

.fix_aside-nav .m-center img {
  margin-top: 0.8rem;
  max-width: 100%;
  height: auto;
}

.fix_aside-nav .m-center h3 {
  margin-top: 0.4rem;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: calc(36 / 17);
}

.fix_aside-nav .m-center p {
  margin-top: 0.4rem;
  font-size: 1.3rem;
  line-height: calc(22 / 13);
}

.item-fix:hover .item-menu {
  -webkit-animation: jello 1s;
  animation: jello 1s;
}
@keyframes jello {
  11.1%,
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.39062deg) skewY(0.39062deg);
  }

  88.8% {
    transform: skewX(-0.19531deg) skewY(-0.19531deg);
  }
}
@keyframes rota {
  0%,
  100%,
  50% {
    transform: scale(1);
  }

  25%,
  75% {
    transform: scale(0.95);
  }
}
/*End Fix box left*/

/*Fix box right*/
.fix_box-right {
}
.shortcut-box {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 9999;
  box-sizing: border-box;
  padding-top: 0.6rem;
  width: 7rem;
  border: #6b7ba6 1px solid;
  border-right: none;
  border-top-left-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem;
  background-image: linear-gradient(
    to right,
    rgba(38, 49, 78, 0.9) 0,
    rgba(24, 30, 49, 0.9) 100%
  );
}

.shortcut-box_list {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
}

.shortcut-box_item {
  position: relative;
  padding: 1rem 0;
  width: 100%;
  color: #fff;
  text-align: center;
}

.shortcut-box_item:first-child {
  overflow: hidden;
  border-radius: 0.7rem 0.7rem 0 0;
}

.shortcut-box_item:last-child {
  overflow: hidden;
  border-radius: 0 0 0.7rem 0.7rem;
}

.shortcut-box_item a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  transition: 0.3s;
}

.shortcut-box_item a .icon,
.shortcut-box_item a .text {
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
}

.shortcut-box_item a .icon img,
.shortcut-box_item a .text img {
  margin: 0 auto;
}

.shortcut-box_item a .text {
  padding: 0.8rem 0 0 0;
  font-size: 1.2rem;
  line-height: calc(20 / 12);
}

.shortcut-box_item:hover > a {
  color: #ff0;
  transition: all ease 0.3s;
  transform: scale(0.9);
}

.shortcut-box_item.line_bottom:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 60%;
  height: 1px;
  background: grey;
  content: "";
}

.shortcut-box_item.qr-box-warp:hover .qr-box {
  opacity: 1;
  visibility: visible;
}

.shortcut-box_link {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.shortcut-box .qr-box {
  position: absolute;
  right: 6.4rem;
  bottom: -1.5rem;
  display: none;
  padding-right: 0.5rem;
  width: 37rem;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.shortcut-box .qr-box a {
  display: inline-block;
  width: 11.5rem;
  height: 12rem;
  background: #3966b7;
  text-align: center;
  border-radius: 1rem 1rem 0 1.5rem;
}

.shortcut-box .qr-box a.zaloLink {
  background: #00acd4;
}

.shortcut-box .qr-box a.telegramLink {
  background: #5d89ff;
}

.shortcut-box .qr-box a img {
  display: block;
  display: inline-block;
  margin-top: 1rem;
  width: 9rem;
  height: 9rem;
}

.shortcut-box .qr-box a p {
  color: #fff;
  text-align: center;
  line-height: 1.5;
}
