.content-tai-xiu {
  padding: 0 20px;
  margin-left: 20px;
  position: relative;

  .container-chuong {
    position: absolute;
    left: 29.5%;
    top: -100px;
    height: 300px;
    width: 100%;
    max-width: 320px;
  }

  .clock {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 20%;
    left: 30%;

    .clock__display {
      height: 58px;
      position: relative;
      width: 58px;

      .circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        transition: stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7);
      }

      .clock__text {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        .clock__amount {
          font-size: 18px;
          color: #36e957;
          background-color: #006f5d;
          border-radius: 50%;
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .clock__text--red span.clock__amount {
        color: #f70b12;
        animation: scaleAnimation 0.5s infinite alternate;
      }
    }
  }

  .bet-result {
    position: absolute;
    left: 40%;
    top: 41%;
    text-align: center;

    .phien-tai-xiu {
      color: white;
      padding-bottom: 5px;
      font-size: 16px;
    }

    .dice-bet__result {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;

      .item {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        margin: 0px 5px;
        background-image: linear-gradient(
          180deg,
          rgba(0, 41, 37, 0.5),
          rgba(0, 41, 37, 0.2)
        );
      }

      img {
        width: 30px;
        margin: 0px 5px;
        opacity: 0;
        transform: scale(0);
        animation-name: showImage;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.bg-game {
  width: 100%;
  background-image: url(../../images/game-bg.jpg);
  font-family: Arial, Helvetica, sans-serif;
}

.content-view {
  margin: 0 auto;
  padding: 10px 0;
  width: 1400px;
  height: 100%;
}

.pc-taste_container {
  background-image: url(../../images/bg.9575f2a6.jpg);
  width: 1396px;
  height: 873px;
  border-radius: 12px;
  position: relative;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header-tai-xiu {
  width: 100%;
  height: 170px;
  background-image: url(../../images/bgheader.png);
  // background-position-x: 0px;
  // background-position-y: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  border-top-right-radius: 15px;
  border-top-left-radius: 12px;

  .phien-tai-xiu {
    text-align: center;
    color: white;
    font-size: 20px;
  }
}

.taste_balance {
  width: 400px;
  height: 80px;
  background-image: url(../../images/tongtien.png);
  background-position-x: 20px;
  background-position-y: 10px;
  background-repeat: no-repeat;
  position: relative;
}

.total-money {
  position: absolute;
  top: 38px;
  left: 120px;
  color: white;
  font-size: 16px;
  font-weight: 550;
}

.taste_menu {
  border: 2px solid #2f7870;
  background-color: #043e39;
  width: 68px;
  height: 349px;
  border-radius: 50px;
  padding: 4px;
  box-sizing: border-box;
}

.taste_menu_list {
  border: 1px solid #295a96;
  height: 100%;
  border-radius: 50px;
  box-sizing: border-box;
}

.taste_menu_item {
  color: #85ccc4;
  line-height: 14px;
  height: 82px;
  position: relative;
  cursor: pointer;
}

.taste_content_item {
  width: 162px;
}

.sanh.taste_menu_item::after {
  display: block;
  content: "";
  width: 24px;
  height: 1px;
  position: absolute;
  bottom: -10px;
  background-color: #1d5952;
}

.pt-5.taste_menu_item::after {
  display: block;
  content: "";
  width: 24px;
  height: 1px;
  position: absolute;
  bottom: -25px;
  background-color: #1d5952;
}

.taste_menu_item img {
  width: 30px;
  height: 30px;
}

.taste_menu_item span {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.play_taste_item {
  background: rgba(0, 140, 255, 0.2);
  box-shadow: 0 4px 4px 0 rgba(0, 26, 60, 0.4);
  border-radius: 4px;
  border: 1px solid rgba(148, 206, 255, 0.8);
  font-size: 12px;
  color: #c1e4ff;
  margin-top: 10px;
  line-height: 16px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 60px;
  background: rgba(74, 255, 250, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 44, 39, 0.5);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  color: #c3edd6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.play_taste_item {
  position: relative;

  span:nth-child(2) {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .tien-tung-o {
    position: absolute;
    left: 5px;

    span {
      font-size: 14px;
    }
  }
}

.taste_history {
  width: 162px;
  height: 347px;
  background: #043e39;
  box-shadow: 2px 0 4px 0 rgba(0, 44, 39, 0.6);
  border-radius: 10px;
  border: 1px solid #2f7870;
  margin: 10px;
}

.is_taste_game_title {
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
}

// Footer
.footer-tai-xiu {
  margin-right: 90px;
}

.chip-tai-xiu {
  max-width: 600px;

  .chip-container {
    width: 62px;
    height: 62px;
    margin: 50px 0px 20px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-chip__isValue {
      position: relative;
      background-image: url(../../images/game_images/img-chip-customize.png);
      width: 50px;
      height: 51px;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        color: #9e4212;
      }
    }

    .rotate-image {
      position: absolute;
      top: 30.5px;
      left: 31px;
      width: 62px;
      height: 62px;
      background-size: cover;
      transform: translate(-50%, -50%) rotate(0deg);
      animation: spin 5s linear infinite;
    }
  }

  img.chip {
    width: 50px;
    height: 51px;
    cursor: pointer;
    z-index: 99;
  }
}

.none {
  display: none;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.footer-dat-cuoc {
  width: 500px;
}

.so-tien-dat-cuoc {
  span {
    color: white;
    font-size: 16px;
  }

  span.text-blue {
    color: #10e7c4;
  }
}

.tong-tien-dat-cuoc {
  background-image: url(../../images/bgsotiendatcuoc.png);
  background-size: cover;
  width: 260px;
  height: 40px;
  margin-bottom: 15px;
  position: relative;
}

.content-tien-dat-cuoc {
  display: flex;
  position: absolute;
  top: 9px;
  left: 20px;
}

.bet_taste_line {
  width: 1px;
  height: 13px;
  background-color: #fff;
  margin: 4px 8px;
}

.footer-button {
  background-size: cover;
  width: 100px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
}

.cai-lai {
  background-image: url(../../images/bgnutgrey.png);
}

.dat-cuoc {
  background-image: url(../../images/bgnutblue.png);
}

// Đặt cược
.play_taste_item {
  position: relative;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.main-content {
  flex-wrap: wrap;
  margin-top: 20px;

  .item.hidden {
    opacity: 0;
    z-index: -1;
  }

  .item.active {
    animation: blinkAnimation 1s linear 5;
  }

  .item {
    width: 23.9%;
    margin: 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: rgba(74, 255, 250, 0.1);
    box-shadow: 0 2px 4px 0 rgba(0, 44, 39, 0.5);
    border: 1px solid hsla(0, 0%, 100%, 0.4);
    color: #c3edd6;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    img {
      width: 80px;
    }

    .item-content {
      font-size: 20px;
      font-weight: 700;
      color: white;
      margin-bottom: 4px;
    }

    img.gold-chip {
      width: 50px;
      position: absolute;
      top: -10px;
      right: 0;
      transform: scale(0);
      transition: transform 0.5s ease-in-out;
    }

    .gold-chip.show {
      animation-name: grow;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    .bet-money {
      position: absolute;
      bottom: 0;
      right: 5px;
    }
  }
}

.history_sicbo {
  height: 292px;
  width: 100%;
  overflow-y: scroll;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5px;
    padding: 3px 5px;

    span {
      color: white;
    }

    .dice {
      img {
        width: 20px;
        margin: 0 2px;
      }
    }
  }

  .item:nth-child(2n) {
    background: rgba(0, 253, 255, 0.1);
  }
}

.history_sicbo::-webkit-scrollbar {
  display: none;
}

.background-opacity {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  pointer-events: none;

  .background-money {
    position: relative;

    span {
      position: absolute;
      top: 35vh;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.text-blue {
  color: #3a81e5;
}

.button_confirm {
  width: 6.62em;
  height: 2em;
  color: #fff;
  font-size: 1.15em;
  border-radius: 4px;
  background: linear-gradient(180deg, #74caff, #1779ff);
}

.background-opacity.background-setup__chip {
  pointer-events: all;
}

.stop-bet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 3s ease-in-out forwards;
}

.fade-out-encourage {
  animation: fadeOut 12s ease-in-out forwards;
}

// Animation
@keyframes scaleAnimation {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes showImage {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes blinkAnimation {
  0% {
    background-color: rgba(26, 255, 224, 0.8);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(26, 255, 224, 0.8);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
