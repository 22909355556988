.header_content {
  padding: 4px 0;
  height: 48px;
  background: #2161b4;
  color: #fff;
  font-size: 12px;
}

.header_content-item {
  height: 100%;
}

.member-nav {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  font-size: 13px;
}

.member-nav__link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
}

.member-nav__link > span {
  line-height: 30px;
}

.member-nav__account {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0e4c9e;
}

.member-nav__link--balance {
  padding: 0 13px 0 8px;
  height: 30px;
  border-radius: 15px;
  background: #0e4c9e;
}

.member-nav__icon {
  margin-right: 6px;
}

.member-nav__reload {
  display: inline-block;
  margin-left: 6px;
  width: 11px;
  height: auto;
  margin-bottom: 3px;
}

.member-nav__link--msg {
  margin: 4px 22px 0 10px;
  height: 21px;
}

.member-nav__link--recharge {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  background: #01a76a;
  -webkit-box-shadow: inset 0 1px 0 rgba(0, 255, 161, 0.2),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(0, 255, 161, 0.2),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
}

.member-nav__link--withdraw {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  background: #f5a833;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
}

.member-nav__link--transfer {
  width: 110px;
  height: 30px;
  border-radius: 15px;
  background: #0085ff;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1.5px 0 rgba(17, 17, 17, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1.5px 0 rgba(17, 17, 17, 0.1);
}

.member-nav__link--history {
  position: relative;
  overflow: visible;
  margin: 0 14px 0 10px;
}

.member-nav__link--betrecord {
  margin: 0 20px 0 14px;
}

.member-nav__link--logout {
  width: 110px;
  height: 30px;
  border-radius: 15px;
  background: #1e6aff;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1.5px 0 rgba(0, 0, 0, 0.1);
}

.member-nav__link--history:after {
  position: absolute;
  top: 50%;
  right: -14px;
  width: 1px;
  height: 14px;
  background: rgba(255, 255, 255, 0.4);
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

a:hover {
  cursor: pointer;
}

.member-nav__link--recharge:hover,
.member-nav__link--withdraw:hover,
.member-nav__link--transfer:hover,
.member-nav__link--logout:hover {
  transform: scale(1.1);
}
