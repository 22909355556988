.wrap-run {
  align-items: center;
  background-color: #000000;
  > .wrapper {
    overflow-x: hidden;
    width: 100%;
    > .target {
      -webkit-animation-duration: 50s;
      animation-duration: 50s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: rightToLeft;
      animation-name: rightToLeft;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      color: #ffffff;
      line-height: 13px;
      margin: 5px 0;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}

@keyframes rightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
