.tab-colum {
  padding: 10px;
  border: 1px solid #5b83ec;
  background-color: rgba(10, 16, 49, 0.9);
}

.tab-colum .tab_ctrl {
  margin-bottom: 20px;
  min-width: 245px;
  border: 1px solid #5b83ec;
  background-color: #171742;
}

.tab-colum .tab_ctrl .tab_btn {
  position: relative;
  padding: 11px 5px 11px 9px;
  border: 1px solid #5b83ec;
  color: #eee;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}

.tab-colum .tab_ctrl .tab_btn.active,
.tab-colum .tab_ctrl .tab_btn:hover {
  color: #ffc514;
  color: #000;
  background: #fdf7ac;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #fdf7ac),
      to(#f1c95a)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #fdf7ac 0,
      #f1c95a 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #fdf7ac 0, #f1c95a 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #fdf7ac 0, #f1c95a 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #fdf7ac),
    to(#f1c95a)
  );
  background: linear-gradient(to top, #fdf7ac 0, #f1c95a 100%);
}

.tab-colum .tab_ctrl .tab_btn.active .icon img,
.tab-colum .tab_ctrl .tab_btn.active .more img,
.tab-colum .tab_ctrl .tab_btn:hover .icon img,
.tab-colum .tab_ctrl .tab_btn:hover .more img {
  -webkit-filter: brightness(0.3);
  filter: brightness(0.3);
}

.tab-colum .tab_ctrl .slogan,
.tab-colum .tab_ctrl .web {
  display: block;
  margin: 40px auto;
  text-align: center;
}

.tab-colum .tab_main {
  padding: 28px 48px;
  min-height: 50vh;
  width: calc(100% - 276px);
}

.tab-colum .tab_main .tab_item:not(:last-child) {
  margin-bottom: 28px;
}

.txt-box {
  padding: 20px 0;
  background: url(../../images/bg-about.jpg) no-repeat top center #07082a;
  background-attachment: fixed;
  color: #eee;
  font-size: 13px;
  line-height: 1.6;
}

// .txt-box.safety {
//     background: url(../../images/bg-safety.jpg) no-repeat top center #07082a
// }

.txt-box .tab_item li,
.txt-box article {
  margin-bottom: 1.5em;
}

.txt-box .tab-colum .tab_ctrl .tab_btn > a {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.txt-box .page-agent_tab_btn {
  text-transform: uppercase;
}

.txt-box .tit {
  position: relative;
  font-weight: 700;
  font-size: 15px;
  line-height: 2em;
}

.txt-box .sub-tit {
  display: table;
  margin: 10px 0;
  padding: 3px 10px;
  border-radius: 20px;
  background-color: #fff45c;
  color: red;
}

.txt-box .agentUrlbyAgentid {
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0 12px;
  width: 60%;
  height: 48px;
  border: #ccc 1px solid;
  border-radius: 6px;
  background: #fff;
  color: #ccc;
  font-size: 15px;
  line-height: 48px;
}

.txt-box .btn {
  display: inline-block;
  padding: 1em 2em;
  border-radius: 8px;
  background: #f01f20;
  color: #fff;
}

.txt-box .btn:not(:last-child) {
  margin-right: 1em;
}

.txt-box table td,
.txt-box table th {
  padding: 4px 8px;
  border: #fedc8c 1px solid;
  text-align: center;
}

.txt-box table th {
  background-color: #fedc8c;
  color: #000;
}

.txt-box .agent-title {
  padding: 35px 0;
  text-align: center;
}

.txt-box hr {
  height: 0.8px;
  background-color: #20214b;
  color: #20214b;
}

.txt-box .agent-reg-box .reg_content {
  padding: 40px 0;
}

.txt-box .agent-reg-box .reg_content_title {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.txt-box .agent-reg-box .reg_content_title-img {
  max-width: 100%;
}

.txt-box .agent-reg-box .reg_content_title-img.modify-margin {
  margin: 5px 0 0 15px;
}

.txt-box .agent-reg-box .reg_content_wrap {
  position: relative;
  margin-top: 40px;
  padding: 0 15px;
  min-height: 570px;
  width: 100%;
  text-align: center;
}

.txt-box .agent-reg-box .reg_content_wrap .reg_formBox {
  margin-top: 40px;
  text-align: left;
}

.txt-box .agent-reg-box .reg_content_wrap .reg_formBox > div {
  width: 50%;
}

.txt-box .agent-reg-box .reg_content_input-group {
  margin-bottom: 30px;
}

.txt-box .agent-reg-box .reg_content_label {
  margin-right: 10px;
  padding-left: 30px;
  width: calc(137px + 60px);
  color: #fff;
  text-align: left;
  letter-spacing: -1px;
  font-size: 16px;
}

.txt-box .agent-reg-box .reg_content_require {
  position: relative;
}

.txt-box .agent-reg-box .reg_content_require::before {
  margin-right: 3px;
  color: red;
  content: "*";
}

.txt-box .agent-reg-box .reg_content_input {
  padding: 3px 10px;
  width: 355px;
  height: 40px;
  border: 1px solid #0258a1;
  border-radius: 6px;
  background-color: rgba(11, 15, 60, 0.6);
}

.txt-box .agent-reg-box .reg_content_input::-webkit-input-placeholder {
  color: #747474;
  white-space: pre-wrap;
}

.txt-box .agent-reg-box .reg_content_input::-moz-placeholder {
  color: #747474;
  white-space: pre-wrap;
}

.txt-box .agent-reg-box .reg_content_input:-ms-input-placeholder {
  color: #747474;
  white-space: pre-wrap;
}

.txt-box .agent-reg-box .reg_content_input:-moz-placeholder {
  color: #747474;
  white-space: pre-wrap;
}

.txt-box .agent-reg-box .reg_content_selects {
  width: 355px;
  height: 40px;
  border: 1px solid #0258a1;
  background-color: rgba(11, 15, 60, 0.6);
  color: #fff;
}

.txt-box .agent-reg-box .reg_content_select {
  margin-right: 5px;
  padding: 3px 5px;
  height: 100%;
  border: 1px solid #0258a1;
  border-radius: 6px;
  background-color: rgba(11, 15, 60, 0.6);
  color: #fff;
}

.txt-box .agent-reg-box .reg_content_select:last-child {
  margin-right: 0;
}

.txt-box .agent-reg-box .reg_content_tip {
  display: inline-block;
  margin-left: 20px;
  color: #8c8a8a;
  text-align: left;
  font-size: 12px;
}

.txt-box .agent-reg-box .reg_content_checkbox {
  margin-left: 20px;
}

.txt-box .agent-reg-box .reg_content_checkbox + .reg_content_tip {
  color: #fff;
}

.txt-box .agent-reg-box .reg_content_button {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 0 5px;
  min-width: 90px;
  width: 120px;
  height: 40px;
  outline: 0;
  border: none;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
}

.txt-box .agent-reg-box .reg_content_button::before {
  display: inline-block;
  width: 0;
  height: 100%;
  content: "";
  vertical-align: middle;
}

.txt-box .agent-reg-box .reg_content_button:hover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  content: "";
}

.txt-box .agent-reg-box .reg_content_button.button-yellow {
  color: #000;
  background: #fdf7ac;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #fdf7ac),
      to(#f1c95a)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #fdf7ac 0,
      #f1c95a 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #fdf7ac 0, #f1c95a 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #fdf7ac 0, #f1c95a 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #fdf7ac),
    to(#f1c95a)
  );
  background: linear-gradient(to top, #fdf7ac 0, #f1c95a 100%);
}

.txt-box .agent-reg-box .reg_content_button.button-blue {
  color: #fff;
  background: #2161b4;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #2161b4),
      to(#133779)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #2161b4 0,
      #133779 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #2161b4 0, #133779 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #2161b4 0, #133779 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #2161b4),
    to(#133779)
  );
  background: linear-gradient(to top, #2161b4 0, #133779 100%);
}

.txt-box .agent-reg-box .reg_content_button-sms {
  margin: 0;
  font-size: 12px;
}

.txt-box .url_button {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  min-width: 90px;
  height: 35px;
  outline: 0;
  border: 0;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.txt-box .url_button.button-green {
  color: #fff;
  background: #33b499;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #33b499),
      to(#258370)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #33b499 0,
      #258370 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #33b499 0, #258370 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #33b499 0, #258370 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #33b499),
    to(#258370)
  );
  background: linear-gradient(to top, #33b499 0, #258370 100%);
}

.txt-box .url_button.button-blue {
  color: #fff;
  background: #015eea;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #015eea),
      to(#00c0fa)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #015eea 0,
      #00c0fa 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #015eea 0, #00c0fa 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #015eea 0, #00c0fa 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #015eea),
    to(#00c0fa)
  );
  background: linear-gradient(to top, #015eea 0, #00c0fa 100%);
}

.tab-colum,
.tab-colum .tab_ctrl .tab_btn > a,
.txt-box .agent-reg-box .reg_content_input-group,
.txt-box .agent-reg-box .reg_content_selects,
.txt-box .agent-reg-box .reg_content_wrap .reg_formBox {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tab-colum {
  padding: 10px;
  border: 1px solid #5b83ec;
  background-color: rgba(10, 16, 49, 0.9);
}

.tab-colum .tab_ctrl {
  margin-bottom: 20px;
  width: 270px;
  border: 1px solid #5b83ec;
  background-color: #171742;
}

.tab-colum .tab_ctrl .tab_btn {
  position: relative;
  padding: 11px 5px 11px 9px;
  border: 1px solid #5b83ec;
  color: #eee;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}

.tab-colum .tab_ctrl .tab_btn.active,
.tab-colum .tab_ctrl .tab_btn:hover {
  color: #ffc514;
  color: #000;
  background: #fdf7ac;
  background: rgba(0, 0, 0, 0) -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #fdf7ac),
      to(#f1c95a)
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(
      bottom,
      #fdf7ac 0,
      #f1c95a 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to top, #fdf7ac 0, #f1c95a 100%)
    repeat scroll 0 0;
  background: -o-linear-gradient(bottom, #fdf7ac 0, #f1c95a 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #fdf7ac),
    to(#f1c95a)
  );
  background: linear-gradient(to top, #fdf7ac 0, #f1c95a 100%);
}

.tab-colum .tab_ctrl .tab_btn.active .icon img,
.tab-colum .tab_ctrl .tab_btn.active .more img,
.tab-colum .tab_ctrl .tab_btn:hover .icon img,
.tab-colum .tab_ctrl .tab_btn:hover .more img {
  -webkit-filter: brightness(0.3);
  filter: brightness(0.3);
}

.tab-colum .tab_ctrl .slogan,
.tab-colum .tab_ctrl .web {
  display: block;
  margin: 40px auto;
  text-align: center;
}

.tab-colum .tab_main {
  padding: 28px 48px;
  min-height: 50vh;
  width: calc(100% - 276px);
}

.tab-colum .tab_main .tab_item:not(:last-child) {
  margin-bottom: 28px;
}

.wrap {
  margin: 0 auto;
  width: 1230px;
}

.hide {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}
.focus {
  color: #fbe38e;
}

.style-link-123 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.focus span a:hover {
  color: white;
}
