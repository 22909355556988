.page-register {
  padding: 5rem 0;
  background: #07082a url("../../images/bg-reg.jpg") no-repeat top center;
  background-size: cover;
}
.head-register {
}
.wrap-register {
  position: relative;
  margin-top: 5rem;
  padding: 0 1.5rem;
  min-height: 57rem;
  width: 100%;
  background: url("../../images/reg-fram.png");
  background-position: top center;
  background-size: contain;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: -3.5rem;
    left: 0;
    width: 100%;
    height: 3.5rem;
    background: url("../../images/reg-framBottom.png") no-repeat;
    background-size: contain;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -3rem;
    left: 0;
    width: 100%;
    height: 3.5rem;
    background: url("../../images/reg-framBottom.png") no-repeat;
    background-size: contain;
  }
  .title {
    text-align: center;
  }
  .box-form {
    margin-top: 4.5rem;
    padding: 0 2rem;
    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;
      position: relative;
      .label {
        white-space: nowrap;
        flex: 0 0 calc(13rem + 5.5rem);
        color: #fff;
        font-size: 1.6rem;
        margin-right: 0.5rem;
        text-align: left;
        position: relative;
      }
      &.required {
        .label {
          &:before {
            content: "*";
            margin-right: 0.4rem;
            margin-bottom: 0.3rem;
            color: red;
          }
        }
      }
      &.validate {
        .valid-code {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1rem;
          width: 6rem;
          height: 2rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        .form-control {
          padding-right: 8rem;
        }
      }
    }
    .form-control {
      height: 4.2rem;
      border: 1px solid #0258a1;
      background-color: rgba(11, 15, 60, 0.6);
      color: rgba(#fff, 0.7);
      box-shadow: none;
      font-size: 1.3rem;
      padding: 0 1.2rem;
      padding-bottom: 0.1rem;
      border-radius: 0.6rem;
      transition: 0.2s;
      &::placeholder {
        position: relative;
        color: rgba(#fff, 0.3);
        white-space: pre-wrap;
        font-size: 1.2rem;
        top: -0.9rem;
      }
      &:focus {
        border-color: red;
      }
    }
    .btn-form {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      .item {
        width: 13rem;
        height: 4.2rem;
        font-size: 1.5rem;
        border-radius: 0.6rem;
        margin: 0 1rem;
        font-weight: 700;
        text-transform: uppercase;
        font-family: $f2;
        letter-spacing: 0.05em;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, 0.3);
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease-in-out;
        }
        span {
          position: relative;
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          box-shadow: 0 0 0.5rem 0 rgba(#fff, 0.2);
          &:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .btn-pri {
        color: #000;
        background: linear-gradient(to top, #fdf7ac 0, #f1c95a 100%);
      }
      .btn-second {
        color: #fff;
        background: linear-gradient(to top, #2161b4 0, #133779 100%);
      }
    }
  }
}

.i-check {
  cursor: pointer;
  margin-bottom: 0;
  display: inline-flex;
  input {
    display: none;
    &:checked + i {
      background-color: #0085ff;
      border-color: #0085ff;
      &:after {
        opacity: 1;
      }
    }
  }
  &.active {
    i {
      background-color: #0085ff;
      border-color: #0085ff;
      &:after {
        opacity: 1;
      }
    }
  }
  i {
    display: inline-block;
    vertical-align: middle;
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    border: solid 1px #fff;
    font-style: normal;
    font-size: 1.7rem;
    line-height: 1.7rem;
    color: #858585;
    margin-right: 1.2rem;
    margin-top: 0.4rem;
    border-radius: 2px;
    &:after {
      @include font("\f00c");
      opacity: 0;
      color: #fff;
      font-size: 1.4rem;
    }
  }

  span {
    font-weight: 400;
    font-size: 1.2rem;
    color: #fff;
    text-align: left;
  }
}
